import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
   
<path d="M1333 2880 c-544 -62 -1006 -439 -1169 -953 -44 -140 -55 -207 -61
-373 -4 -127 -2 -183 12 -265 95 -576 516 -1017 1087 -1141 85 -18 134 -22
283 -22 159 0 193 3 291 26 563 132 972 564 1070 1128 20 118 14 396 -11 507
-123 551 -538 962 -1083 1073 -112 22 -314 32 -419 20z m382 -36 c424 -69 811
-364 995 -757 85 -182 119 -324 127 -532 9 -236 -30 -429 -127 -632 -190 -399
-545 -672 -986 -759 -133 -27 -376 -24 -514 5 -444 94 -802 386 -981 800 -80
186 -115 379 -106 586 14 346 139 641 379 890 318 331 752 474 1213 399z"/>
<path d="M596 2349 c-23 -4 -47 -16 -58 -29 -17 -21 -17 -25 -3 -46 9 -13 11
-24 5 -24 -19 0 -10 -40 13 -54 30 -20 246 -20 254 0 7 19 58 18 73 -1 10 -13
41 -15 171 -14 l159 0 0 32 0 32 23 -32 c27 -39 49 -41 76 -8 l20 26 3 -23 c3
-21 9 -23 72 -26 55 -2 72 0 78 13 11 19 64 20 72 0 6 -17 86 -22 86 -5 0 6
-18 44 -41 85 -41 75 -41 75 -83 75 -41 0 -42 -1 -69 -56 -16 -31 -26 -59 -23
-61 3 -3 17 20 33 51 25 51 30 56 60 56 30 0 35 -5 65 -62 18 -35 34 -66 36
-70 2 -5 -10 -8 -27 -8 -17 0 -31 5 -31 10 0 6 -18 10 -39 10 -22 0 -43 -4
-46 -10 -9 -15 -63 -12 -62 3 0 6 -1 40 -1 75 l-2 62 -44 0 c-39 0 -48 -4 -67
-32 l-23 -31 -27 32 c-24 29 -31 32 -70 29 l-44 -3 1 -47 0 -48 -52 0 -53 0
-3 48 c-3 46 -3 47 -40 50 l-37 3 -3 -58 -3 -58 -30 58 c-29 56 -30 57 -72 57
-42 0 -43 -1 -72 -57 -17 -32 -30 -50 -31 -40 0 9 -4 17 -9 17 -4 0 -6 -10 -4
-23 3 -14 -2 -29 -13 -38 -23 -19 -135 -19 -157 0 -32 26 -19 33 48 26 45 -5
65 -4 65 4 0 6 -12 11 -27 11 -16 0 -45 5 -65 11 -76 20 -33 79 57 79 49 0 95
-20 95 -41 0 -13 -46 -11 -69 2 -20 13 -79 3 -65 -11 8 -7 95 -15 132 -11 18
2 15 36 -5 53 -22 19 -74 26 -127 17z m317 -79 l37 -70 -30 0 c-16 0 -30 5
-30 10 0 6 -20 10 -45 10 -25 0 -45 -4 -45 -10 0 -5 -14 -10 -31 -10 -17 0
-29 3 -27 8 2 4 18 35 37 70 30 56 37 62 65 62 28 0 35 -7 69 -70z m97 20 l0
-50 60 0 c53 0 60 -2 60 -20 0 -18 -7 -20 -85 -20 l-85 0 0 70 c0 68 1 70 25
70 23 0 25 -3 25 -50z m231 15 c13 -19 27 -35 31 -35 4 0 19 16 34 35 21 28
33 35 60 35 l34 0 0 -70 c0 -68 -1 -70 -25 -70 -22 0 -25 4 -25 38 l-1 37 -32
-38 c-18 -20 -39 -37 -47 -37 -8 0 -26 17 -40 38 l-25 37 -3 -37 c-3 -33 -7
-38 -28 -38 -23 0 -24 2 -24 70 l0 70 34 0 c27 0 38 -7 57 -35z"/>
<path d="M832 2275 c-10 -22 -9 -25 8 -25 21 0 26 16 12 38 -6 9 -12 6 -20
-13z"/>
<path d="M1730 2265 l0 -85 76 0 c41 0 84 5 95 10 13 7 19 7 19 0 0 -17 77
-12 84 5 3 9 18 15 35 15 16 0 31 -6 35 -15 7 -19 86 -21 86 -2 0 6 -9 27 -20
45 -25 41 -25 42 5 42 23 0 25 -3 25 -50 l0 -50 35 0 c34 0 34 1 41 50 6 44
10 50 30 50 l23 0 -24 -42 c-13 -23 -22 -45 -19 -50 9 -15 74 -9 80 7 4 9 19
15 39 15 20 0 35 -6 39 -15 5 -15 66 -22 79 -9 4 4 -12 42 -34 85 l-41 79 -43
0 c-31 0 -45 -5 -49 -16 -4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 10 18 16 38 16
28 0 34 -6 69 -70 l38 -70 -30 0 c-17 0 -31 5 -31 10 0 6 -20 10 -45 10 -25 0
-45 -4 -45 -10 0 -5 -14 -10 -30 -10 l-30 0 24 48 c15 30 22 57 19 75 l-5 27
-104 0 c-91 0 -104 -2 -104 -17 0 -15 -1 -15 -13 1 -9 13 -23 17 -52 14 -34
-3 -42 -8 -62 -42 l-22 -40 -6 27 c-12 45 -40 57 -132 57 l-83 0 0 -85z m169
65 c29 -15 45 -64 30 -92 -18 -35 -27 -38 -111 -38 l-78 0 0 70 0 70 71 0 c39
0 78 -4 88 -10z m205 -55 c20 -36 36 -68 36 -70 0 -3 -13 -5 -30 -5 -16 0 -30
5 -30 10 0 6 -18 10 -39 10 -22 0 -43 -4 -46 -10 -3 -5 -20 -10 -36 -10 l-30
0 27 48 c51 90 53 92 83 92 26 0 34 -8 65 -65z m196 45 c0 -16 -7 -20 -35 -20
l-35 0 0 -50 c0 -47 -2 -50 -25 -50 -23 0 -25 3 -25 50 l0 50 -35 0 c-28 0
-35 4 -35 20 0 19 7 20 95 20 88 0 95 -1 95 -20z"/>
<path d="M1797 2294 c-4 -4 -7 -18 -7 -31 0 -22 3 -24 43 -21 37 3 42 6 42 28
0 21 -5 25 -35 28 -20 2 -39 0 -43 -4z m73 -29 c0 -10 -10 -15 -30 -15 -20 0
-30 5 -30 15 0 10 10 15 30 15 20 0 30 -5 30 -15z"/>
<path d="M2027 2286 c-11 -28 -8 -36 13 -36 23 0 25 9 7 34 -13 18 -14 18 -20
2z"/>
<path d="M1507 2286 c-11 -28 -8 -36 13 -36 23 0 25 9 7 34 -13 18 -14 18 -20
2z"/>
<path d="M2362 2275 c-10 -22 -9 -25 8 -25 21 0 26 16 12 38 -6 9 -12 6 -20
-13z"/>
<path d="M815 2110 c-74 -8 -133 -38 -191 -96 -61 -60 -87 -122 -88 -206 -1
-149 87 -242 373 -393 118 -63 192 -119 215 -164 78 -152 -64 -299 -255 -262
-68 13 -149 50 -187 84 -32 30 -80 133 -88 189 -5 42 -27 69 -42 53 -3 -2 -5
-93 -5 -202 -1 -193 -1 -198 20 -201 16 -2 22 4 26 27 6 27 11 31 41 31 19 0
47 -5 63 -11 165 -65 334 -63 442 5 160 102 205 281 107 431 -37 56 -97 100
-296 215 -149 87 -235 151 -255 190 -29 55 -13 141 32 183 28 25 111 57 148
57 57 0 153 -36 202 -76 53 -43 89 -113 100 -194 7 -44 12 -55 26 -55 17 0 19
13 17 194 0 107 -3 198 -6 203 -8 13 -31 -9 -38 -37 -9 -35 -38 -39 -103 -12
-114 48 -165 57 -258 47z m-123 -113 c-49 -52 -64 -129 -37 -195 19 -45 45
-73 110 -121 60 -45 324 -202 345 -206 8 -1 14 -8 12 -15 -1 -6 2 -9 8 -5 5 3
15 3 21 -1 8 -4 7 -9 -2 -15 -11 -7 -11 -9 3 -9 39 0 108 -125 108 -194 0 -45
-26 -126 -50 -155 -17 -19 -23 -21 -35 -11 -16 13 -21 35 -6 26 9 -6 21 40 21
85 0 16 -4 29 -10 29 -5 0 -10 8 -10 18 0 10 -13 36 -30 58 -16 21 -30 45 -30
53 0 8 -3 12 -6 8 -3 -3 -14 2 -25 11 -10 10 -78 51 -151 91 -223 124 -300
186 -335 270 -39 90 -9 209 70 276 44 38 64 39 29 2z m486 -28 c1 -23 -1 -38
-7 -35 -5 3 -11 1 -13 -6 -4 -11 -48 55 -48 73 0 5 15 9 33 9 30 0 32 -2 35
-41z m-528 -911 l43 -48 -51 0 -50 0 -4 45 c-4 50 1 71 12 58 4 -4 26 -29 50
-55z m520 -10 c0 -13 -39 -48 -54 -48 -7 0 -7 3 1 8 6 4 17 20 22 35 10 26 31
29 31 5z"/>
<path d="M2083 2098 c-5 -7 -24 -47 -43 -88 -18 -41 -53 -117 -77 -168 -23
-51 -43 -95 -43 -98 0 -2 -21 -50 -46 -106 -51 -114 -71 -159 -104 -233 -12
-27 -33 -75 -47 -105 -14 -30 -29 -66 -35 -80 -8 -21 -17 -6 -82 140 -40 91
-85 192 -101 225 -15 33 -44 98 -65 145 -21 47 -57 126 -80 175 -23 50 -48
105 -55 124 -25 74 -31 42 -28 -154 l4 -195 60 -137 c34 -76 68 -154 77 -173
8 -19 55 -125 103 -235 79 -179 91 -200 113 -203 15 -2 26 2 26 8 0 11 33 90
93 220 14 30 37 83 51 117 14 35 37 87 51 115 53 117 206 472 211 494 3 13 10
24 15 24 5 0 9 5 9 11 0 6 -9 8 -21 4 -18 -6 -20 -4 -14 17 15 47 54 116 70
122 9 3 36 6 59 6 41 0 43 -2 44 -30 0 -16 -4 -32 -9 -35 -5 -4 -9 -198 -9
-481 0 -399 2 -480 15 -511 8 -19 15 -37 15 -39 0 -8 -116 -3 -129 5 -11 7
-11 12 0 32 11 19 14 109 14 444 0 231 -3 428 -7 438 -23 55 -28 -15 -28 -396
0 -221 -5 -420 -10 -442 -13 -57 -49 -85 -111 -85 -42 0 -49 -3 -49 -20 0 -20
5 -20 248 -18 212 3 247 5 247 18 0 11 -15 16 -57 20 -42 4 -64 11 -80 28
l-23 23 -3 493 -3 494 26 30 c23 27 33 31 83 34 43 2 57 7 57 18 0 13 -26 15
-161 18 -122 2 -163 -1 -171 -10z m-643 -465 c212 -470 210 -465 255 -445 14
7 25 9 25 5 0 -20 -84 -192 -91 -187 -11 7 -18 21 -145 309 -177 403 -164 366
-164 466 0 50 4 88 9 85 4 -3 54 -108 111 -233z"/>
<path d="M573 818 c-59 -29 -23 -78 58 -78 27 0 49 -5 49 -10 0 -19 -31 -23
-69 -10 -21 7 -46 10 -57 8 -18 -3 -18 -4 4 -26 18 -18 33 -22 85 -22 67 0 97
17 97 53 0 18 -55 37 -107 37 -18 0 -33 5 -33 11 0 8 21 10 70 5 74 -8 88 1
48 28 -26 18 -111 20 -145 4z"/>
<path d="M783 758 l-40 -73 30 -3 c20 -2 33 2 39 12 12 21 74 21 82 1 3 -9 18
-15 37 -15 17 0 29 3 27 8 -2 4 -19 37 -38 75 -32 62 -37 67 -66 67 -30 0 -35
-6 -71 -72z m81 6 c9 -23 8 -24 -13 -24 -14 0 -18 5 -14 20 6 24 19 26 27 4z"/>
<path d="M970 755 l0 -75 85 0 c84 0 85 0 85 25 0 24 -3 25 -60 25 l-60 0 0
50 c0 47 -2 50 -25 50 -24 0 -25 -2 -25 -75z"/>
<path d="M1152 755 c3 -74 3 -75 31 -75 25 0 27 3 27 43 l0 42 28 -42 c15 -24
34 -43 43 -43 9 0 28 18 43 40 14 22 29 40 31 40 3 0 2 -18 -1 -40 -6 -40 -6
-40 25 -40 l31 0 0 75 0 75 -34 0 c-28 0 -39 -7 -64 -41 l-30 -40 -28 40 c-25
35 -34 41 -67 41 l-37 0 2 -75z"/>
<path d="M1458 761 c-21 -38 -38 -72 -38 -75 0 -13 58 -5 64 9 3 9 19 15 41
15 22 0 38 -6 41 -15 4 -9 19 -15 40 -15 l33 0 -39 73 c-35 65 -42 72 -72 75
-31 2 -35 -1 -70 -67z m82 -1 c9 -18 8 -20 -15 -20 -23 0 -24 2 -15 20 6 11
13 20 15 20 2 0 9 -9 15 -20z"/>
<path d="M1750 828 c-1 -2 -2 -36 -3 -76 l-2 -72 78 0 c90 0 117 17 117 74 0
54 -36 76 -121 76 -38 0 -69 -1 -69 -2z m135 -73 c0 -16 -8 -21 -42 -23 -40
-3 -43 -2 -43 23 0 25 3 26 43 23 34 -2 42 -7 42 -23z"/>
<path d="M1977 761 c-20 -37 -37 -71 -37 -75 0 -13 58 -5 64 9 3 9 19 15 41
15 22 0 38 -6 41 -15 4 -9 19 -15 39 -15 l34 0 -42 75 c-38 68 -45 75 -72 75
-28 0 -35 -7 -68 -69z m83 -1 c9 -18 8 -20 -15 -20 -23 0 -24 2 -15 20 6 11
13 20 15 20 2 0 9 -9 15 -20z"/>
<path d="M2120 805 c0 -22 4 -25 36 -25 l37 0 -5 -50 c-5 -49 -5 -50 23 -50
28 0 29 2 29 50 l0 50 35 0 c31 0 35 3 35 25 0 25 0 25 -95 25 -95 0 -95 0
-95 -25z"/>
<path d="M2313 758 l-40 -73 30 -3 c20 -2 33 2 39 12 12 21 74 21 82 1 5 -14
66 -23 66 -10 0 3 -17 37 -37 75 -35 64 -41 70 -70 70 -29 0 -35 -6 -70 -72z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
